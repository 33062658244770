import React, { Component } from 'react';
import Layout from "../components/layout/layout"
import SEOComponent from "../components/seo"
import AmberScore from "../components/reusable/AmberScore";
import classnames from 'classnames';
import InternationalTerms from "../components/terms-and-condition/InternationalTerms"
import DomesticTerms from "../components/terms-and-condition/DomesticT&C"
 class Terms extends Component {

state = {
    selected_tab: 0
}

changetab = (selected_tab) => {
  this.setState({selected_tab});
}

render()
  {
    const { selected_tab } = this.state;
    return(
            <Layout>
              <SEOComponent title="Amber by inFeedo | Terms & Conditions" />
              <div class="content has-text-centered columns flex-wrap mar-top-160">
                      <h2 class="text-block-17 column is-12 is-paddingless">inFeedo Terms and Conditions for SaaS Services</h2>
                      <div className="columns flex-wrap">
                            {/* <ul>
                                <li onClick={this.changetab.bind(this, 0)} className={classnames('', {'is-active': selected_tab === 0})}><button>Domestic</button></li>
                                <li onClick={this.changetab.bind(this, 1)} className={classnames('', {'is-active': selected_tab === 1})}><button>International</button></li>
                            </ul> */}
                            <DomesticTerms/>
                      </div>
                {/* {!selected_tab ? <DomesticTerms></DomesticTerms> : <InternationalTerms></InternationalTerms>} */}
              </div>
              <AmberScore/>
            </Layout>
          )
  }
 }

export default Terms


